import { useEffect, useRef, useState } from 'react'
import { 
  enforceFormat, 
  enforceEmailOnFocusOut, 
  formatCurrency, 
  formatToPhone
} from '../utils'

export default function InputBasic({
  actionData, 
  name, 
  label, 
  type, 
  required,
  className,
  onChangeRef
}) {
  const [invalid, setInvalid] = useState(false)
  const hasError = (actionData && actionData[name])
  const inputRef = useRef(null)
  useEffect(() => {
    switch (type) {
      case 'tel':
        inputRef.current.addEventListener('keydown', enforceFormat)
        inputRef.current.addEventListener('keyup', formatToPhone)
      break
      case 'email':
        inputRef.current.addEventListener('focusout', (event) => enforceEmailOnFocusOut(event, (valid) => {
          setInvalid(!valid)
          console.log('Valid', invalid)
        }))
      break
      case 'text':
        if (name === 'orgAnnualBudget' || name === 'grantAmount') {
          inputRef.current.addEventListener('keydown', enforceFormat)
          inputRef.current.addEventListener('focusout', formatCurrency)
        }
      break
      default:
        return
    }
  }, [])
  return (
    <div className={`form-group ${className} ${hasError && 'error'}`}>
      <label htmlFor={name}>{label}{required && <span></span>}</label>
        <span className="input-wrap">
          <input type={type} name={name} onChange={onChangeRef} ref={inputRef} />
        </span>

      {
        hasError && <span className="error-msg">{actionData[name]}</span>
      }
    </div>
  )
}